<template lang="pug">
v-container.pa-0(fill-height, fluid, grid-list-xl)
  v-layout.ma-0(justify-center, wrap)
    v-flex.pa-0.ma-6(md12)
      v-divider
      v-layout(row)
        v-flex(md3, @click="$vuetify.goTo('#clientes-table')")
          material-stats-card(
            color="blue",
            icon="fa-solid fa-user",
            title="Clientes Atualizados / Atual",
            :value="clientesAtualizados",
            sub-icon="fa-solid fa-tag",
            :sub-text="titulo"
          )

        v-flex(md3, @click="$vuetify.goTo('#erros-table')")
          material-stats-card(
            color="red",
            icon="fa-solid fa-bug",
            title="Erros Únicos",
            :value="errosUnicos",
            sub-icon="fa-solid fa-tag",
            :sub-text="titulo"
          )

        v-flex(md3, @click="$vuetify.goTo('#executaveis-table')")
          material-stats-card(
            color="green",
            icon="far fa-window-maximize",
            title="Executáveis CS / SyS",
            :value="executaveis",
            sub-icon="fa-solid fa-tag",
            :sub-text="titulo"
          )

        v-flex(md3, @click="$vuetify.goTo('#scripts-table')")
          material-stats-card(
            color="yellow darken-2",
            icon="fa-solid fa-scroll",
            title="Scripts",
            :value="scripts",
            sub-icon="fa-solid fa-tag",
            :sub-text="titulo"
          )

    v-flex#clientes-table(md12)
      h2 Clientes
      v-card
        v-data-table(
          :headers="fieldsClientes",
          :items="dadosClientes",
          :footer-props="footprops",
          :no-data-text="'Nenhum cliente encontrado.'"
        )
          template(v-slot:item.nome="{ item }")
            router-link(:to="`/clientes/detalhes/${item['hash']}`") {{ item["nome"] }}

          template(v-slot:item.nestaversao="{ item }")
            v-icon(:color="item['nestaversao'] ? 'success' : 'error'") {{ item["nestaversao"] ? "fa-check" : "fa-close" }}

    v-flex#erros-table(md12)
      h2 Erros
      v-card
        v-data-table(
          :headers="fieldsErros",
          :items="dadosErros",
          :footer-props="footprops",
          :no-data-text="'Nenhum erro encontrado.'"
        )

    v-flex#executaveis-table(md12)
      h2 Executáveis
      v-tabs(v-model="tab")
        v-tab(key="CS") ControlSoft
        v-tab(key="SYS") SySafra
      v-tabs-items(v-model="tab")
        v-tab-item.pa-1(key="CS")
          v-card(elevation="3")
            v-data-table(
              :headers="fieldsExecutaveis",
              :items="dadosExecutaveisCS",
              :footer-props="footprops",
              :no-data-text="'Nenhum executável encontrado.'"
            )
              template(v-slot:item.url="{ item }")
                a(:href="item['url']") {{ item["url"] }}

        v-tab-item.pa-1(key="SYS")
          v-card(elevation="3")
            v-data-table(
              :headers="fieldsExecutaveis",
              :items="dadosExecutaveisSYS",
              :footer-props="footprops",
              :no-data-text="'Nenhum executável encontrado.'"
            )
              template(v-slot:item.url="{ item }")
                a(:href="item['url']") {{ item["url"] }}

    v-flex#scripts-table(md12)
      h2 Scripts
      v-card
        v-data-table(
          :headers="fieldsScripts",
          :items="dadosScripts",
          :footer-props="footprops",
          :no-data-text="'Nenhum script encontrado.'"
        )
</template>

<script>
import { mapMutations } from "vuex";
import api from "@/api";

export default {
  data: () => ({
    tab: "CS",
    footprops: {
      itemsPerPageOptions: [30, 50, 100, 200, 300, 500, -1],
      showCurrentPage: true,
      showFirstLastPage: true,
      itemsPerPageAllText: "Todos",
      itemsPerPageText: "Itens por página",
    },
    versao: "",
    titulo: "Detalhes",

    dadosClientes: [],
    fieldsClientes: api.versoes.fieldsClientes,
    qtdClientes: "0",
    qtdClientesAtualizados: "0",

    dadosErros: [],
    fieldsErros: api.erros.fieldsAgrupadosVersao,

    dadosExecutaveisCS: [],
    dadosExecutaveisSYS: [],
    fieldsExecutaveis: api.versoes.fieldsExecutaveis,

    dadosScripts: [],
    fieldsScripts: api.versoes.fieldsScripts,
  }),
  methods: {
    ...mapMutations("app", ["setTitle", "isControl"]),
  },
  computed: {
    clientesAtualizados() {
      return `${this.qtdClientes} / ${this.qtdClientesAtualizados}`;
    },
    errosUnicos() {
      return this.dadosErros.length.toString();
    },
    executaveis() {
      return `${this.dadosExecutaveisCS.length} / ${this.dadosExecutaveisSYS.length}`;
    },
    scripts() {
      return this.dadosScripts.length.toString();
    },
  },
  async mounted() {
    this.isControl(true);
    this.setTitle("Detalhes");
    api.refreshAuthProd(this.$store.state.auth);

    let clientes, erros, executaveis, scripts;

    try {
      const versao = this.$route.params.versao;
      [clientes, erros, executaveis, scripts] = await Promise.all([
        api.versoes.getDetalhes(versao),
        api.erros.getAgrupadosVersao(versao),
        api.versoes.getExecutaveis(versao),
        api.versoes.getScripts(versao),
      ]);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    }

    // Padronizar os dados
    clientes = clientes
      .filter((row) => row.hash !== null)
      .map((row) => {
        if (row.data && typeof row.data === "string") {
          row.data = `${new Date(
            row.data
          ).toLocaleDateString()} ${row.data.substring(11, 19)}`;
        }
        return row;
      });

    executaveis.forEach((row) => {
      const t = row.url.substring(row.url.lastIndexOf("/") + 1, row.url.length);
      row.name = t;
    });

    scripts.forEach((row) => {
      row.script = `${row.script.substring(0, 300)}...`;
    });

    this.dadosClientes = clientes;
    this.dadosErros = erros;
    this.dadosExecutaveisSYS = executaveis.filter((x) =>
      x.name.toLowerCase().includes("sy")
    );

    this.dadosExecutaveisCS = executaveis.filter(
      (x) => !x.name.toLowerCase().includes("sy")
    );
    this.dadosScripts = scripts;

    this.qtdClientes = this.dadosClientes.length.toString();
    this.qtdClientesAtualizados = this.dadosClientes
      .filter((x) => x.nestaversao)
      .length.toString();

    this.titulo =
      this.qtdClientes > 0
        ? this.dadosClientes[0].versao
        : this.$route.query.versaodesc;
  },
  created() {
    this.$store.dispatch("auth/verify");
  },
};
</script>

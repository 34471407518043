var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fill-height":"","fluid":"","grid-list-xl":""}},[_c('v-layout',{staticClass:"ma-0",attrs:{"justify-center":"","wrap":""}},[_c('v-flex',{staticClass:"pa-0 ma-6",attrs:{"md12":""}},[_c('v-divider'),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{attrs:{"md3":""},on:{"click":function($event){return _vm.$vuetify.goTo('#clientes-table')}}},[_c('material-stats-card',{attrs:{"color":"blue","icon":"fa-solid fa-user","title":"Clientes Atualizados / Atual","value":_vm.clientesAtualizados,"sub-icon":"fa-solid fa-tag","sub-text":_vm.titulo}})],1),_c('v-flex',{attrs:{"md3":""},on:{"click":function($event){return _vm.$vuetify.goTo('#erros-table')}}},[_c('material-stats-card',{attrs:{"color":"red","icon":"fa-solid fa-bug","title":"Erros Únicos","value":_vm.errosUnicos,"sub-icon":"fa-solid fa-tag","sub-text":_vm.titulo}})],1),_c('v-flex',{attrs:{"md3":""},on:{"click":function($event){return _vm.$vuetify.goTo('#executaveis-table')}}},[_c('material-stats-card',{attrs:{"color":"green","icon":"far fa-window-maximize","title":"Executáveis CS / SyS","value":_vm.executaveis,"sub-icon":"fa-solid fa-tag","sub-text":_vm.titulo}})],1),_c('v-flex',{attrs:{"md3":""},on:{"click":function($event){return _vm.$vuetify.goTo('#scripts-table')}}},[_c('material-stats-card',{attrs:{"color":"yellow darken-2","icon":"fa-solid fa-scroll","title":"Scripts","value":_vm.scripts,"sub-icon":"fa-solid fa-tag","sub-text":_vm.titulo}})],1)],1)],1),_c('v-flex',{attrs:{"id":"clientes-table","md12":""}},[_c('h2',[_vm._v("Clientes")]),_c('v-card',[_c('v-data-table',{attrs:{"headers":_vm.fieldsClientes,"items":_vm.dadosClientes,"footer-props":_vm.footprops,"no-data-text":'Nenhum cliente encontrado.'},scopedSlots:_vm._u([{key:"item.nome",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/clientes/detalhes/" + (item['hash']))}},[_vm._v(_vm._s(item["nome"]))])]}},{key:"item.nestaversao",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item['nestaversao'] ? 'success' : 'error'}},[_vm._v(_vm._s(item["nestaversao"] ? "fa-check" : "fa-close"))])]}}])})],1)],1),_c('v-flex',{attrs:{"id":"erros-table","md12":""}},[_c('h2',[_vm._v("Erros")]),_c('v-card',[_c('v-data-table',{attrs:{"headers":_vm.fieldsErros,"items":_vm.dadosErros,"footer-props":_vm.footprops,"no-data-text":'Nenhum erro encontrado.'}})],1)],1),_c('v-flex',{attrs:{"id":"executaveis-table","md12":""}},[_c('h2',[_vm._v("Executáveis")]),_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{key:"CS"},[_vm._v("ControlSoft")]),_c('v-tab',{key:"SYS"},[_vm._v("SySafra")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{key:"CS",staticClass:"pa-1"},[_c('v-card',{attrs:{"elevation":"3"}},[_c('v-data-table',{attrs:{"headers":_vm.fieldsExecutaveis,"items":_vm.dadosExecutaveisCS,"footer-props":_vm.footprops,"no-data-text":'Nenhum executável encontrado.'},scopedSlots:_vm._u([{key:"item.url",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item['url']}},[_vm._v(_vm._s(item["url"]))])]}}])})],1)],1),_c('v-tab-item',{key:"SYS",staticClass:"pa-1"},[_c('v-card',{attrs:{"elevation":"3"}},[_c('v-data-table',{attrs:{"headers":_vm.fieldsExecutaveis,"items":_vm.dadosExecutaveisSYS,"footer-props":_vm.footprops,"no-data-text":'Nenhum executável encontrado.'},scopedSlots:_vm._u([{key:"item.url",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item['url']}},[_vm._v(_vm._s(item["url"]))])]}}])})],1)],1)],1)],1),_c('v-flex',{attrs:{"id":"scripts-table","md12":""}},[_c('h2',[_vm._v("Scripts")]),_c('v-card',[_c('v-data-table',{attrs:{"headers":_vm.fieldsScripts,"items":_vm.dadosScripts,"footer-props":_vm.footprops,"no-data-text":'Nenhum script encontrado.'}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }